import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
// @mui
import { Button, Stack, IconButton, InputAdornment, TextField, styled, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import Iconify from '../../../components/iconify'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import AuthContext from '../../../services/context/authContext'
import { getLocalAndParse, setLocalStore } from '../../../services/utils/localUtils'

import { login, forgotPassword, completePassword, amplifyResetPassword } from '../../../services/authentication/loginService'

// ----------------------------------------------------------------------

const StyledError = styled('div')(() => ({
  color: '#cc0033',
  display: 'inline-block',
  fontSize: 14
}))

export default function LoginForm ({ passwordForgotten, setPasswordForgotten, resetPassword, setResetPassword, newPasswordRequired, setNewPasswordRequired }) {
  const navigate = useNavigate()
  const context = useContext(AuthContext)

  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)
  const [errorContent, setErrorContent] = useState(null)

  const [username, setUsername] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [currentUser, setCurrentUser] = useState(null)
  const [newPassword, setNewPassword] = useState(null)

  useEffect(() => {
    setNewPassword(password)
  }, [password])

  useEffect(() => {
    const loginState = getLocalAndParse('loginState')
    if (loginState) {
      const user = context.currentUser
    }
  }, [])

  const handleClick = async () => {
    if (!passwordForgotten) {
      if (resetPassword) {
        const res = await amplifyResetPassword(username, code, newPassword)
        if (res.code !== 0) {
          setErrorContent(res.message)
          setError(true)
        } else setResetPassword(false)
      } else if (!newPasswordRequired) {
        login(username, password).then((user) => {
          if (user) {
            setCurrentUser(user)
            console.log('there')
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setNewPasswordRequired(true)
            } else {
              setError(false)
              setErrorContent(null)
              localStorage.setItem('email', username)
              localStorage.setItem('company', user.attributes['custom:Company'])
              localStorage.setItem('companyLogoUrl', user.attributes['custom:LogoURL'])
              console.log('there2')
              try {
                context.updateCurrentUser(user)
              } catch (e) {
                console.log('hey e', e)
              }
              navigate('/')
            }
          } else {
            setError(true)
            setErrorContent('Please verify your email and password')
          }
        })
      } else {
        completePassword(currentUser, newPassword)
          .then((user) => {
            if (user) {
              setError(false)
              setErrorContent(null)
              setLocalStore('user', user)
              setLocalStore('loginState', true)
              context.updateCurrentUser(user)
              setNewPasswordRequired(false)
            } else {
              setError(true)
              setErrorContent('8-character minimum length')
            }
          })
          .catch((e) => {
            console.log('handleClick completePassword error', e)
          })
      }
    } else if (!resetPassword) {
      forgotPassword(username)
        .then((data) => {
          if (data.statusCode !== 200) {
            setError(true)
            setErrorContent(data.body)
            return
          }
          setResetPassword(true)
          setPasswordForgotten(false)
        })
    }
  }

  return (
    <>
      <Stack spacing={3} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {!newPasswordRequired && (
          <TextField
            name="email"
            label="Email"
            value={username}
            variant="outlined"
            onChange={e => { setUsername(e.target.value) }}
            sx={{
              width: '350px'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              )
            }}
          />
        )}
        {resetPassword && (
          <TextField
            name="code"
            label="Code"
            value={code}
            variant="outlined"
            onChange={e => { setCode(e.target.value) }}
            sx={{
              width: '350px'
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                </InputAdornment>
              )
            }}
          />
        )}
        {!passwordForgotten && (
          <TextField
            name="password"
            label={newPasswordRequired ? 'New Password' : 'Password'}
            type={showPassword ? 'text' : 'password'}
            value={newPasswordRequired ? newPassword : password}
            variant="outlined"
            onChange={e => {
              if (!newPasswordRequired) {
                setPassword(e.target.value)
              } else {
                setNewPassword(e.target.value)
              }
            }}
            sx={{ width: '350px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ padding: '10px' }}>
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      </Stack>

      {error && <StyledError>{errorContent}</StyledError>}
      {false && !resetPassword &&
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
          <Button
            onClick={() => { setPasswordForgotten((prev) => !prev); setError(null) }}
            sx={{
              height: '30px',
              borderRadius: '20px'
            }}
          >
            <Typography
              padding='5px'
              fontSize='12px'
              fontWeight='bold'
            >
              {passwordForgotten ? 'Log in' : 'Password forgotten ?'}
            </Typography>
          </Button>
        </Stack>}

      <LoadingButton
        type="submit"
        variant="contained"
        onClick={handleClick}
        style={{
          borderRadius: '10px',
          width: 'auto',
          height: '40px'
        }}
      >
        {passwordForgotten ? 'Continue' : resetPassword ? 'Reset password' : 'Log in'}
      </LoadingButton>
    </>
  )
}

LoginForm.propTypes = {
  passwordForgotten: PropTypes.bool.isRequired,
  setPasswordForgotten: PropTypes.func.isRequired,
  resetPassword: PropTypes.bool.isRequired,
  setResetPassword: PropTypes.func.isRequired,
  newPasswordRequired: PropTypes.bool.isRequired,
  setNewPasswordRequired: PropTypes.func.isRequired
}
