import { Container, CircularProgress } from '@mui/material'

const LoadingCircle = () => {
  return (
        <Container
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '80vh'
            }}
        >
            <CircularProgress />
        </Container>
  )
}

export default LoadingCircle
