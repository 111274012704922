import React, { useState, useContext } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import {
  Link,
  Container,
  Typography,
  Collapse,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import AuthContext from '../services/context/authContext'

// sections
import { LoginForm } from '../sections/auth/login'

import logoKleep from '../resources/logoKleep.png'

export default function LoginPage () {
  const [passwordForgotten, setPasswordForgotten] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)

  const context = useContext(AuthContext)

  return (
    <>
      <Dialog
        open={true}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            width: '500px',
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 'auto',
            transition: 'height 1s ease-out'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: '1px lightgrey solid',
            width: '200px'
          }}>
            <img
              src={logoKleep}
              style={{
                width: '80px',
                marginTop: '20px',
                marginLeft: '10px',
                marginBottom: '20px'
              }}
            />
          </Box>
          <Typography
            align='center'
            fontSize='15px'
            marginTop='10px'
            fontWeight='bold'
          >
            {passwordForgotten ? 'Are you having trouble signing in?' : (newPasswordRequired ? 'Please update your password' : 'Welcome to your dashboard')}
          </Typography>
          {!newPasswordRequired && (
            <Typography
              align='center'
              fontSize='15px'
            >
              {passwordForgotten ? 'Enter your email to get started' : 'Log in to continue'}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <LoginForm
            passwordForgotten={passwordForgotten}
            setPasswordForgotten={setPasswordForgotten}
            resetPassword={resetPassword}
            setResetPassword={setResetPassword}
            newPasswordRequired={newPasswordRequired}
            setNewPasswordRequired={setNewPasswordRequired}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
