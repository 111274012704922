import React, { useEffect } from 'react'

import { Helmet } from 'react-helmet-async'
// @mui
import { Grid, Container } from '@mui/material'
// components
import LoadingCircle from '../components/loading/loadingPage'
// redux
import { useSelector, useDispatch } from 'react-redux'

import { fetchSummaryData } from '../features/summarySlice'
import Usage from '../components/summary/usage'
import Recommandations from '../components/summary/recommandations'
import Routes from '../components/summary/routes'

export default function Summary () {
  const dispatch = useDispatch()
  const summaryStatus = useSelector((state) => state.summary.status)

  useEffect(() => {
    if (summaryStatus === 'idle') {
      dispatch(fetchSummaryData())
    }
  }, [summaryStatus, dispatch])

  if (summaryStatus !== 'succeeded') {
    return <LoadingCircle />
  }

  return (
    <>
      <Helmet>
        <title>Usage</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={12} sx={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100%' }} >
            <Usage />
            <Recommandations />
            <Routes />
          </Grid>
      </Container >
    </>
  )
}
