import { Donut } from '../../sections/@dashboard/app'
import React from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { Box, Card, Grid, Typography } from '@mui/material'
import CompletionRate from '../customers/CompletionRate'
import CustomerFeedbacks from '../customers/CustomerFeedbacks'

export default function Routes () {
  const summaryData = useSelector((state) => state.summary.data)
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.routes

  return (
    <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%' }}>
      <Typography style={{ fontFamily: 'Arial', fontSize: 28, fontWeight: 'bold', color: '#121212', fontStyle: 'italic', marginTop: '50px' }}>
        {translation.title}
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        component="div"
        sx={{
          borderBottom: '1px lightgrey solid',
          marginTop: 2,
          width: '100%'
        }}
      />
      <Grid margin={'auto'} width={'100%'}>
        <Box style={{ margin: 'auto', display: 'grid', gridTemplateColumns: '55% 30%', justifyContent: 'center', textAlign: 'center', marginTop: '50px', gap: '50px' }}>
          <CompletionRate title={translation.completion_rate} data={[{ title: Number(summaryData.usage.completion_rate).toFixed(2) * 100 }, { title: ((summaryData.journey.ended_journey[0]._count._all / summaryData.journey.started_journey[0]._count) * 100).toFixed() }, { title: ((summaryData.journey.ended_journey[1]._count._all / summaryData.journey.started_journey[1]._count) * 100).toFixed() }]} sx={{ marginBottom: '20px' }} />
          <Card
            sx={{
              bgcolor: '#E9EFF6',
              alignItems: 'center',
              justifySelf: 'center',
              boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212', marginTop: '20px' }}>
              {translation.donut.title}
            </Typography>
            <Box
              margin={'auto'}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Donut
                title={''}
                subheader={''}
                donutLabels={[translation.donut.labels[0], translation.donut.labels[1]]}
                donutData={[Number(summaryData.route.QUESTION[1]).toFixed(2) * 100, Number(summaryData.route.SCAN[1]).toFixed(2) * 100]}
                withBackground={false}
              />
            </Box>
          </Card>
        </Box>
        <Box width={'100%'} sx={{ marginBottom: '20px', marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
          <CustomerFeedbacks
            title={translation.completion_rate}
            data={[
              { title: Number(summaryData.usage.completion_rate).toFixed(2) * 100 },
              { title: ((summaryData.journey.ended_journey[0]._count._all / summaryData.journey.started_journey[0]._count) * 100).toFixed() },
              { title: ((summaryData.journey.ended_journey[1]._count._all / summaryData.journey.started_journey[1]._count) * 100).toFixed() }
            ]}
          />
        </Box>

        {/* <ToolRate data={[{ title: String(Number(summaryData.route.SCAN[1]).toFixed(2) * 100) + '%', subtitle: translation.rate.subtitle }, { title: String(summaryData.notes.scan.positive[0] + summaryData.notes.scan.negative[0]), subtitle: String(summaryData.notes.scan.count) }, { title: String((summaryData.notes.scan.positive[1].toFixed(2) * 100).toFixed()), subtitle: String((summaryData.notes.scan.negative[1].toFixed(2) * 100).toFixed()) }]} title={translation.rate.titles[0]} />
          <ToolRate data={[{ title: String(Number(summaryData.route.QUESTION[1]).toFixed(2) * 100) + '%', subtitle: translation.rate.subtitle }, { title: String(summaryData.notes.questions.positive[0] + summaryData.notes.questions.negative[0]), subtitle: String(summaryData.notes.questions.count) }, { title: String((summaryData.notes.questions.positive[1].toFixed(2) * 100).toFixed()), subtitle: String((summaryData.notes.questions.negative[1].toFixed(2) * 100).toFixed()) }]} title={translation.rate.titles[1]} /> */}
      </Grid>
    </Grid>
  )
}
