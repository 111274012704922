import { API_URL } from './constants'

interface ApiFetchParams {
  endpoint: string;
  method?: string;
  body?: any;
}

export const apiFetch = async ({ endpoint, method = 'GET', body = null }: ApiFetchParams): Promise<any> => {
  const fullUrl = new URL(`${API_URL}${endpoint}`)
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  if (body) {
    options.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(fullUrl.toString(), options)

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }
    return await response.json()
  } catch (error) {
    console.error('Fetch error:', error)
    throw error
  }
}

export async function fetchFromSessionOrApi<T> (apiCall: () => Promise<T>, storageKey: string): Promise<T> {
  const data = sessionStorage.getItem(storageKey)

  if (data !== null) {
    return JSON.parse(data) as T
  } else {
    const fetchedData = await apiCall()
    sessionStorage.setItem(storageKey, JSON.stringify(fetchedData))
    return fetchedData
  }
}
