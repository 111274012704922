import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation, Navigate, Link as ReactRouter } from 'react-router-dom'
// @mui
import { styled, alpha } from '@mui/material/styles'
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material'
// mock
import account from '../../../_mock/account'
// hooks
import useResponsive from '../../../hooks/useResponsive'
// components
import Scrollbar from '../../../components/scrollbar'
import NavSection from '../../../components/nav-section'
import placeDesTendances from '../../../resources/placeDesTendances.png'
import logoKleep from '../../../resources/logoKleep.png'

//
import navConfig from './config'
// translation
import i18n from 'i18next'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280

// ----------------------------------------------------------------------

Nav.propTypes = {
  language: PropTypes.object,
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
}

export default function Nav ({ language, openNav, onCloseNav }) {
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).menu
  const { pathname } = useLocation()

  const isDesktop = useResponsive('up', 'lg')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#ECEFF2' }
      }}
    >
      <Box sx={{ px: 11, marginTop: '10px', display: 'inline-flex' }}>
        <Button component={ReactRouter} to="/">
            <img
              src={logoKleep}
              style={{ margin: 'auto', marginTop: '10px', width: '140px' }}
            />
          </Button>
      </Box>

      <hr style={{ width: '96px', marginBottom: '30px', border: '0.5px solid' }}></hr>

      <NavSection data={navConfig()} />

      <Box sx={{ px: 2.5, pb: 20, mt: 10 }}>
        <Stack alignItems="center" spacing={1} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>

          <Box sx={{ textAlign: 'center' }}>
            <Typography mt={{ fontStyle: 'italic', fontSize: 12 }} variant="body1">
              {translation.help.label}
            </Typography>
          </Box>

          <Button style={{ textTransform: 'none', backgroundColor: '#DFE1E9', color: 'black' }} href="https://www.klipfit.com/contact" target="_blank" variant="contained">
            {translation.help.button}
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH }
      }}
    >
      {isDesktop
        ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
          )
        : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
          )}
    </Box>
  )
}
