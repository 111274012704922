// component
import SvgColor from '../../../components/svg-color'
import i18n from 'i18next'

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

const navConfig = () => {
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).menu
  console.log('translation', translation)

  return (
    [
      {
        title: translation.usage.title,
        path: '/usage',
        icon: icon('ic_analytics')
      }
      // {
      //   title: translation.sales.title,
      //   path: '/sales',
      //   icon: icon('ic_tag')
      // },
      // {
      //   title: translation.returns.title,
      //   path: '/returns',
      //   icon: icon('ic_return')
      // }
    ]
  )
}

export default navConfig
