import React from 'react'
// @mui
import { Typography, Box } from '@mui/material'
// components
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export default function TopRecommandations ({ title, type, subheader, data }) {
  const summaryData = useSelector((state) => state.summary.data)

  return (
    <Box
      sx={{
        px: '20px',
        py: '15px',
        borderRadius: '20px',
        backgroundColor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        width: '42%',
        alignItems: 'center',
        textAlign: 'center',
        margin: '10px',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }} width={'55%'}>
        {title}
      </Typography>
      <Typography width={'95%'} fontStyle='italic' fontSize='13px' fontWeight={'300'} color='grey' sx={{ marginBottom: '5px' }}>
        {subheader}
      </Typography>
      <Box
        sx={{
          width: '100%',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          textAlign: 'left'
        }}
      >
        {data.map((v, idx) => (
          <Box
            key={idx}
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5px',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '75%', paddingLeft: v.title ? '0px' : '45px' }}>
              <Typography style={{ fontFamily: 'Arial', fontSize: 16, fontWeight: 'bold', color: '#121212' }}>
                {idx + 1}.{' '}
                {v.title ? String(v.brand).toUpperCase() : v.brand}
                {v.title && `: ${v.title}`}
              </Typography>
            </Box>

            <Typography fontWeight={'bold'} color={'#5381B8'} sx={{ paddingRight: v.title ? '0px' : '45px' }}>
              {type === 'product' ? v.count + ' (' + ((v.count / summaryData.top_recommendations.product_list_size) * 100).toFixed(1) + '%)' : v.count + ' (' + ((v.count / summaryData.top_recommendations.brand_count_size) * 100).toFixed(1) + '%)'}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box >
  )
}

TopRecommandations.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  data: PropTypes.array
}
