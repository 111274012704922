import { Typography, Card, Box } from '@mui/material'
import PropTypes from 'prop-types'

export default function RecommandationSummary ({ title, subtitle, data }) {
  return (
    <Card
      sx={{
        p: 3,
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '40%',
        height: '50%',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" sx={{ pb: 2, color: 'text.secondary', marginBottom: '5px' }}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '15px' }}>
        {data.map((item, index) => (
          <Box key={index} sx={{ flex: 1 }}>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#121212', pb: '0.5' }}>
              {item.value}
            </Typography>
            <Typography variant="body2" sx={{ color: '#5381B8', fontWeight: 'bold' }}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  )
}

RecommandationSummary.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
}
