import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

Donut.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  withBackground: PropTypes.bool,
  donutData: PropTypes.array.isRequired,
  donutLabels: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default function Donut ({ donutLabels, donutData }) {
  return (
    <ReactApexChart
      type="donut"
      series={donutData}
      options={{
        chart: {
          type: 'donut'
        },
        toolbar: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            }
          }
        },
        colors: ['#3E8BE4', '#17385F'],
        legend: {
          markers: {
            width: '16rem',
            height: '16rem',
            radius: '16rem'
          },
          position: 'bottom',
          fontSize: '16rem',
          fontWeight: 700,
          labels: {
            colors: '#121212'
          }
        },
        labels: donutLabels
      }}
    />
  )
}
