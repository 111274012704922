import { Typography, Card, Box } from '@mui/material'
import PropTypes from 'prop-types'
import LinearProgressWithLabel from '../../sections/@dashboard/app/LinearProgress'
import i18n from 'i18next'

export default function CompletionRate ({ title, subtitle = '', data }) {
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.routes.rate

  return (
    <Card
      sx={{
        p: 3,
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }} marginBottom={5}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" sx={{ pb: 2, color: 'text.secondary' }}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '100%', gap: '10px', borderBottom: '2px dashed grey', marginBottom: '20px', paddingBottom: '10px' }}>
          <Typography variant="h5" color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
            General
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={13} value={data[0].title} />
        </Box>
        <Box sx={{ marginTop: '20px', display: 'grid', gridTemplateColumns: '30% 60%', width: '100%', gap: '10px', marginBottom: '10px' }}>
          <Typography variant="h6" color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
          {translation.titles[0]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={data[1].title} />
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '100%', gap: '10px' }}>
          <Typography variant="h6" color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
          {translation.titles[1]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={data[2].title} />
        </Box>
      </Box>

    </Card>
  )
}

CompletionRate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.number
    })
  )
}
