import React, { useState, useEffect, createContext } from 'react'
import {
  getLocalAndParse,
  getLocalSessionAndParse
} from '../utils/localUtils'

interface AuthContextType {
    brandMetadata: any;
    loginState: boolean;
    currentUser: any;
    userMetadata: any;
    updateBrandMetadata: (content: any) => void;
    updateLoginState: (content: any) => void;
    updateCurrentUser: (content: any) => void;
    updateUserMetadata: (content: any) => void;
  }

const AuthContext = createContext<AuthContextType>({
  brandMetadata: null,
  loginState: false,
  currentUser: null,
  userMetadata: null,
  updateBrandMetadata: () => { return null },
  updateLoginState: () => { return null },
  updateCurrentUser: () => { return null },
  updateUserMetadata: () => { return null }
})

export const AuthContextProvider: React.FC<React.PropsWithChildren<{object}>> = (props) => {
  const [brandMetadata, setBrandMetadata] = useState<any>()
  const [loginState, setLoginState] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>()
  const [userMetadata, setUserMetadata] = useState<any>()

  const updateBrandMetadata = (content: any) => { setBrandMetadata(content) }
  const updateLoginState = (content: any) => { setLoginState(content) }
  const updateCurrentUser = (content: any) => {
    setCurrentUser(content)
  }
  const updateUserMetadata = (content: any) => { setUserMetadata(content) }

  useEffect(() => {
    const loggedIn = localStorage.getItem('loginState')
    const brandData = getLocalAndParse('brandMetadata')
    const user = getLocalSessionAndParse('user')

    if (loggedIn) { setLoginState(true) }
    if (brandData) { setBrandMetadata(brandData) }
    if (user) { setCurrentUser(user) }
  }, [brandMetadata, loginState, currentUser, setBrandMetadata, setLoginState, setCurrentUser])

  return (
    <AuthContext.Provider
        value={{
          brandMetadata,
          loginState,
          currentUser,
          userMetadata,
          updateBrandMetadata,
          updateLoginState,
          updateCurrentUser,
          updateUserMetadata
        }}
    >
        {props.children}
    </AuthContext.Provider>

  )
}

export default AuthContext
