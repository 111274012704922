import { apiFetch } from './API'

export const SummaryAPI = {
  findSummaryData: async () => {
    const company = localStorage.getItem('company') || ''
    try {
      const response = await apiFetch({
        endpoint: '/analytics/get-summary-page/',
        method: 'POST',
        body: { retailer: company }
      })

      if (response) {
        return response
      } else {
        console.log('No product data found in response')
        return null
      }
    } catch (error) {
      console.error('Error in findUsage:', error)
      throw error
    }
  }
}
