import React from 'react'

import { Helmet } from 'react-helmet-async'
// @mui
import { styled } from '@mui/material/styles'
import { Container, Typography, Grid, Link, Box, Button } from '@mui/material'
// sections
import { SignUpForm } from '../sections/auth/signup'
import LanguagePopover from '../layouts/dashboard/header/LanguagePopover'
import klipfitLogo from '../resources/klipfitLogo.png'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  borderRadius: '5%',
  backgroundColor: 'white',
  padding: '50px',
  width: '800px',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)'
}))

// ----------------------------------------------------------------------

export default function SignUpPage () {
  return (
    <div style={{ backgroundColor: '#ECEFF2', display: 'flex' }}>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>

      <Grid container alignItems='center' justifyContent='center' style={{ minHeight: '100vh' }} >
        <StyledRoot>
          <Container maxWidth="sm">
              <img
                src={klipfitLogo}
                style={{ margin: 'auto', width: '140px' }}
              />
              <hr style={{ marginTop: '20px', width: '413px' }}></hr>
              <Typography variant="body1" sx={{ mb: 2 }} align='center' style={{ paddingTop: '10px', fontSize: '18px' }}>
                  <i><b>Sign up to continue</b></i> {''} <br />
                  <Link variant="subtitle1" underline='none' href="/login">
                    <i><b>Already registered ?</b></i>
                  </Link>
                </Typography>
              <SignUpForm />
          </Container>
        </StyledRoot>
      </Grid>
      <div style={{ bottom: '50px', right: '50px', position: 'fixed' }}>
        <Box sx={{ textAlign: 'center' }} style={{ marginRight: '60px' }}>
            <Typography mt={{ fontStyle: 'italic', fontSize: 12 }} variant="body1">
              Need help ?
            </Typography>
        </Box>
        <Button style={{ backgroundColor: '#DFE1E9', color: 'black', marginRight: '20px' }} href="https://www.klipfit.com/contact" target="_blank" variant="contained">
              Contact Us
          </Button>
        <LanguagePopover />
      </div>
    </div>
  )
}
