import { Typography, Card, Box } from '@mui/material'
import PropTypes from 'prop-types'

export default function ProfileType ({ title, subtitle, data }) {
  return (
    <Card
      sx={{
        margin: 'auto',
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}

    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography sx={{ pb: 1 }} style={{ fontFamily: 'Arial', fontSize: 16, fontWeight: 'bold', color: '#5381B8' }}>
          {subtitle}
        </Typography>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
        {data.map((item, index) => (
          <Box key={index} sx={{ flex: 1 }}>
            <Typography style={{ height: '50%', fontFamily: 'Arial', fontSize: 30, fontWeight: 'bold', color: '#121212' }}>
              {item.value}
            </Typography>
            <Typography style={{ fontFamily: 'Arial', fontSize: 16, fontWeight: 'bold', color: '#5381B8' }}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Card>
  )
}

ProfileType.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
}
