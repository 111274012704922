import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import SimpleLayout from './layouts/simple'
//
import LoginPage from './pages/LoginPage'
import SignUpPage from './pages/SignUp'
import Page404 from './pages/Page404'
import Summary from './pages/Summary'
import Sales from './pages/Sales'
import Returns from './pages/Returns'
import ConfirmSignUp from './pages/ConfirmSignUp'
// ----------------------------------------------------------------------
import { Auth } from 'aws-amplify'

const LANGS = [
  {
    value: 'fr',
    label: 'Français',
    icon: '/assets/icons/ic_flag_fr.svg'
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg'
  }
]

async function checkAuth () {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch {
    return false
  }
}

export default function Router () {
  const [language, setLanguage] = useState(LANGS[0])
  const location = useLocation()
  const [auth, setAuth] = useState(undefined)
  const routes = auth === undefined
    ? useRoutes([
      {
        path: '*',
        element: null
      }])
    : useRoutes([
      {
        path: '/',
        element: auth === true ? <DashboardLayout language={language} setLanguage={setLanguage} /> : <Navigate to="/login" />,
        children: [
          { element: <Navigate to="/usage" />, index: true },
          { path: 'usage', element: <Summary language={language} /> }
          // { path: 'sales', element: <Sales language={language} /> },
          // { path: 'returns', element: <Returns language={language} /> }
        ]
      },
      {
        path: 'login',
        element: auth === false ? <LoginPage /> : <Navigate to="/usage" />
      },
      {
        path: 'signup',
        element: auth === false ? <SignUpPage /> : <Navigate to="/usage" />
      },
      {
        path: 'confirm-signup',
        element: auth === false ? <ConfirmSignUp /> : <Navigate to="/usage" />
      },
      {
        element: <SimpleLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" />, index: true },
          { path: '404', element: <Page404 /> },
          { path: '*', element: <Navigate to="/404" /> }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/404" replace />
      }
    ])

  useEffect(() => {
    checkAuth().then((res) => setAuth(res))
  }, [location])

  return routes
}
