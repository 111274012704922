import React from 'react'
// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ScrollToTop from './components/scroll-to-top'
import { StyledChart } from './components/chart'
// Amplify
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'
// i18
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
// ----------------------------------------------------------------------

const importAll = (r) => {
  const translations = {}
  r.keys().forEach((key) => {
    const language = key.replace('./', '').replace('.json', '')
    translations[language] = r(key)
  })
  return translations
}

const translations = importAll(require.context('./translations', false, /\.json$/))

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: 'fr', // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  })
Amplify.configure(awsconfig)

export default function App () {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  )
}
