import { Auth } from 'aws-amplify'

export async function login (username: string, password: string) {
  try {
    const user = await Auth.signIn(username, password)
    return user
  } catch (error) {
    console.log('error signing in', error)
  }
}

export async function forgotPassword (email: string) {
  let statusCode: number
  let body: string

  try {
    await Auth.forgotPassword(email)
    statusCode = 200
    body = 'Verification code has been successfully sent'
  } catch (error: any) {
    console.log('there', error.code)
    if (error.code === 'LimitExceededException') {
      statusCode = 400
      body = 'Too many attempts, please wait'
    } else if (error.code === 'InvalidParameterException') {
      statusCode = 400
      body = 'Please verify your email'
    } else if (error.code === 'NotAuthorizedException') {
      statusCode = 400
      console.log(error.message)
      body = 'Your account has not been verified. Please contact the support.'
    } else if (error.code === 'CodeDeliveryFailureException') {
      statusCode = 400
      body = 'Your email adress is unattainable. Please contact the support.'
    } else if (error.code === 'UserNotFoundException') {
      statusCode = 400
      body = 'Sorry, we could not found your email'
    } else {
      statusCode = 401
      body = error.message
    }
  }

  return {
    statusCode,
    body
  }
}

export async function amplifyResetPassword (email: string, code: string, newPassword: string) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
    return { code: 0 }
  } catch (error: unknown) {
    console.error('Error while changing password :', error)
    let errorMessage = ''
    if (typeof error === 'object' && error !== null) {
      errorMessage = (error as { message?: string }).message ?? 'An error occured'
    }
    return {
      code: -1,
      message: errorMessage
    }
  }
}

export async function completePassword (user: any, newPassword: string) {
  try {
    const newPasswordSucces = await Auth.completeNewPassword(user, newPassword)
    console.log('completeNewPassword INPUT', user, newPassword)
    console.log('completeNewPassword RESULT', newPasswordSucces)
    return newPasswordSucces
  } catch (e) {
    return false
  }
}
