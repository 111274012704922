import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
// @mui
import { Typography, Box } from '@mui/material'
// components
import { useChart } from '../../../components/chart'

// ----------------------------------------------------------------------

Chart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataType: PropTypes.string
}

export default function Chart ({ title, subheader, chartLabels, chartData, dataType }) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    stroke: chartData[1].stroke,
    colors: ['#FFD600', '#417CD8'],
    fill: chartData[1].fill,
    yaxis: chartData[1].yaxis,
    legend: chartData[1].legend,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} ${dataType}`
          }
          return y
        }
      }
    }
  })

  return (
    <div style={{ /* boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.25)',  borderRadius: '20px' */ }}>
      {/* <Card {...other}> */}
      <Typography variant="h4" fontWeight={'bold'} sx={{ textAlign: 'start', marginLeft: '57px', paddingTop: '30px' }}>
        {title}
      </Typography>
      <Box sx={{ p: 0, pb: 0 }} dir="ltr">
        <ReactApexChart type={chartData[0].type} series={chartData[0].data} options={chartOptions} height={300} />
      </Box>
      {/* </Card> */}

    </div>
  )
}
