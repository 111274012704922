export default function createHistory (history) {
  const occurrencesLast7Days = {}
  const occurrencesLast30Days = {}
  const allOccurrences = {}
  const now = new Date()
  const last7Days = new Date()
  last7Days.setDate(now.getDate() - 8)
  const last30Days = new Date()
  last30Days.setDate(now.getDate() - 31)

  const historyEntries = Object.entries(history)
  historyEntries.sort((a, b) => new Date(a[0]) - new Date(b[0]))

  historyEntries.forEach(([date, count]) => {
    const currentDate = new Date(date)
    allOccurrences[date] = count

    if (currentDate >= last7Days) {
      occurrencesLast7Days[date] = count
    }
    if (currentDate >= last30Days) {
      occurrencesLast30Days[date] = count
    }
  })

  const cumulativeOccurrencesLast7Days = {}
  const cumulativeOccurrencesLast30Days = {}
  const cumulativeAllOccurrences = {}
  let cumulativeCountAll = 0
  const currentDate = new Date()

  Object.entries(allOccurrences).forEach(([date, count]) => {
    cumulativeCountAll += count
    cumulativeAllOccurrences[date] = cumulativeCountAll

    const dateObj = new Date(date)
    const diffInDays = (currentDate - dateObj) / (1000 * 60 * 60 * 24)

    if (diffInDays <= 8) {
      cumulativeOccurrencesLast7Days[date] = cumulativeCountAll
    }
    if (diffInDays <= 31) {
      cumulativeOccurrencesLast30Days[date] = cumulativeCountAll
    }
  })

  return [
    occurrencesLast7Days,
    occurrencesLast30Days,
    allOccurrences,
    cumulativeOccurrencesLast7Days,
    cumulativeOccurrencesLast30Days,
    cumulativeAllOccurrences
  ]
}

export function calculateAverageNewUsersPerDay (history) {
  const entries = Object.entries(history)

  entries.sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))

  let totalNewUsers = 0
  let daysWithNewUsers = 0

  for (let i = 1; i < entries.length; i++) {
    if (entries[i][1] > entries[i - 1][1]) {
      const newUsers = entries[i][1] - entries[i - 1][1]
      totalNewUsers += newUsers
      daysWithNewUsers++
    }
  }

  const averageNewUsersPerDay = daysWithNewUsers > 0 ? totalNewUsers / daysWithNewUsers : 0

  return averageNewUsersPerDay.toFixed(1)
}
