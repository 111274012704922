import { useState, useEffect } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material'
import i18n from 'i18next'
import PropTypes from 'prop-types'

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'fr',
    label: 'Français',
    icon: '/assets/icons/ic_flag_fr.svg'
  },
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg'
  }
]

// ----------------------------------------------------------------------

export default function LanguagePopover ({ language, setLanguage }) {
  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = (option) => {
    try {
      i18n.changeLanguage(option.value)
      if (option.value) { setLanguage(option) }
      setOpen(null)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={language.icon} alt={language.label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === language.value}
              onClick={() => { handleClose(option) }}
            >
              <Box
                component="img"
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

LanguagePopover.propTypes = {
  language: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired
}
