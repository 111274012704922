import React, { useState } from 'react'

import { Stack, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { confirmSignUp } from '../../../services/authentication/signUpService'

import { useLocation, useNavigate } from 'react-router-dom'

export default function ConfirmSignUpForm () {
  const location = useLocation()
  const navigate = useNavigate()

  const [code, setCode] = useState('')

  const handleClick = () => {
    if (code !== '') {
      console.log(location.state.email)
      confirmSignUp(location.state.email, code).then((res) => {
        if (res) {
          navigate('/login')
        }
      })
    }
  }

  return (

    <>
    <Stack spacing={3}>

      <TextField
          label="Code"
          value={code}
          onChange={e => {
            setCode(e.target.value)
          }
        }
        />
    </Stack>
    <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Confirm Sign Up
      </LoadingButton>
    </>
  )
}
