import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// components
import Iconify from '../../../components/iconify'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import { signUp } from '../../../services/authentication/signUpService'

// ----------------------------------------------------------------------

const StyledError = styled('div')(({ theme }) => ({
  color: '#cc0033',
  display: 'inline-block',
  fontSize: 14
}))

export default function SignUpForm () {
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [error, setError] = useState('')

  const handleClick = () => {
    if (email === '') {
      setEmailError(true)
    }
    if (password === '') {
      setPasswordError(true)
    }
    if (!emailError && !passwordError) {
      signUp(email, password).then((res) => {
        if (res) {
          navigate('/confirm-signup', { state: { email } })
        } else {
          setError('Error signing up')
          console.log('Error in sign up form when clicking on button')
        }
      })
    } else {
      setError('Error signing up')
      console.log('Error in sign up form when clicking on button')
    }
  }

  return (
    <>
      <Stack spacing={3}>

        <TextField
          error={emailError}
          name="email"
          label="Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value)
            if (e.target.value !== '') {
              setEmailError(false)
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            )
          }}
        />

        <TextField
          error={passwordError}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => {
            setPassword(e.target.value)
            if (e.target.value !== '') {
              setPasswordError(false)
            }
          }
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>

      <StyledError>{error}</StyledError>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} style={{ marginTop: '40px', borderRadius: '10px' }}>
        Sign Up
      </LoadingButton>
    </>
  )
}
