export function getLocalAndParse (key: any) {
  const stringVal: string | null = localStorage.getItem(key)
  if (stringVal === null || stringVal === 'undefined') {
    return undefined
  }
  return JSON.parse(stringVal)
}

export function setLocalStore (key: any, value: any) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getLocalSessionAndParse (key: any) {
  const stringVal: string | null = sessionStorage.getItem(key)
  if (stringVal === null || stringVal === 'undefined') {
    return false
  }
  return JSON.parse(stringVal)
}

export function setSessionStore (key: any, value: string) {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeLocalStore (key: any) {
  localStorage.removeItem(key)
}
