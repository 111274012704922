import { Typography, Card, Box, Grid } from '@mui/material'
import LinearProgressWithLabel from '../../sections/@dashboard/app/LinearProgress'
import { useSelector } from 'react-redux'
import i18n from 'i18next'

export default function CustomerFeedbacks () {
  const summaryData = useSelector((state) => state.summary.data)
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.routes.rate

  return (
    <Card
      sx={{
        p: 3,
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '65%',
        margin: 'auto',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }} marginBottom={5}>
        {translation.title}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'left' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '60%' }}>
          <Typography variant="h5" color='#17385F' sx={{ fontWeight: 'bold' }}>
            {translation.satisfaction}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={13} value={summaryData.notes.total.positive[1] * 100} />
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '60%', gap: '10px' }}>
          <Typography variant="body2" color='#17385F' sx={{ fontWeight: 'regular', marginBottom: '40px', textAlign: 'left' }}>
            {translation.average}
          </Typography>
        </Box>
        <Grid sx={{ display: 'flex', gridTemplateColumns: '40% 40%', width: '100%', gap: '105px', justifyContent: 'space-between', borderTop: '1px dashed grey', paddingTop: '40px', textAlign: 'start', paddingLeft: '45px', paddingRight: '45px' }}>
          <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '10px', margin: 'auto' }}>
            <Typography variant="h5" color='#17385F' sx={{ fontWeight: 'bold' }}>
              {translation.titles[1]}
            </Typography>
            <LinearProgressWithLabel variant="determinate" size={7} value={summaryData.notes.questions.positive[1] * 100} />
            <Typography variant="body2" color='#17385F' sx={{ fontWeight: 'regular' }}>
              {`${String(summaryData.notes.questions.positive[0] + summaryData.notes.questions.negative[0])} ${translation.feedbacks} ${String(summaryData.notes.questions.grader_count)} ${translation.users}`}
            </Typography>
          </Box>
          <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h5" color='#17385F' sx={{ fontWeight: 'bold' }}>
              {translation.titles[0]}
            </Typography>
            <LinearProgressWithLabel variant="determinate" size={7} value={summaryData.notes.scan.positive[1] * 100} />
            <Typography variant="body2" color='#17385F' sx={{ fontWeight: 'regular' }}>
              {`${String(summaryData.notes.scan.positive[0] + summaryData.notes.scan.negative[0])} ${translation.feedbacks} ${String(summaryData.notes.scan.grader_count)} ${translation.users}`}
            </Typography>
          </Box>
        </Grid>

      </Box>

    </Card>
  )
}
