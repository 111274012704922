import { Auth } from 'aws-amplify'

export async function signUp (email: string, password: string) {
  console.log(email)
  console.log(password)
  try {
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email
      }
    })
    return user
  } catch (error) {
    console.log('error signing up:', error)
  }
}

export async function confirmSignUp (username, code) {
  console.log('in')
  try {
    await Auth.confirmSignUp(username, code)
    return true
  } catch (error) {
    console.log('error confirming sign up', error)
    return false
  }
}
