import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Link, Button } from '@mui/material'

const Logo = forwardRef(({ disabledLink = false }) => {
  const logo = (
      <Button style={{ fontSize: 32, color: 'black' }}> Kleep</Button>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool
}

export default Logo
